@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.btn {
  color: #fff;
  background-color: #000;
}

#mainGalleryImage {
  margin-bottom: 20px;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

p {
  font-size: 18px;
  padding-bottom: 20px;
  color: #000;
}

h4.piece {
  font-size: 18px;
  margin-top: 20px;
  letter-spacing: 1px;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

/* .container-center {
  height: 100%;
  position: relative;
} */

.container-center {
  display: flex;
  align-items: center;
}

.container-center .flex-col {
  flex-direction: column;
}

.container-center .row {
  align-items: center;
}

.container-center img {
  margin: 20px 0px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bg-light {
  border: none;
}

.btn-black {
  background-color: #000;
  color: #fff;
}

.btn-danger {
  background-color: #d9534f !important;
}

.btn-black:hover {
  color: #fff;
}

.logo {
  height: 75px;
  margin-right: 20px;
}

footer {
  font-size: 12px;
}

/* .piece {
  display: flex;
  flex-wrap: wrap;
} */

/* .piece img {
  max-width: 300px;
} */

model-viewer {
  height: 600px;
  width: 600px;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

.jacket {
  width: 300px;
}

.piece-container {
  display: flex;
  justify-content: space-evenly;
}
.nft-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nft-pic {
  width: 245px;
  height: 245px;
}

.post-thumbnail {
  max-width: 300px;
  width: auto;
  height: auto;
  margin-right: 50px;
}

.post-body .main-image {
  float: left;
  margin: 0 25px 25px 0;
}

.post-body .inner-body img {
  width: 100%;
}

/* .archive-post {
  margin-bottom: 75px;
} */

.nav-item {
  margin-right: 0;
}

.article {
  /* width: 80%; */
  margin: 0 auto;
}

.article .body {
  display: flex;
  margin-bottom: 75px;
}

.video-footage {
  margin-bottom: 50px;
  display: flex;
}

.video-footage span {
  width: 42%;
text-align: right;
margin-right: 30px;
}

.video-footage iframe {
  width: 100%;
}
.text-center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
p-0 {
  padding-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .text-center-mobile {
    text-align: center;
  }
  .video-footage {
    flex-direction: column;
  }
  /* .video-footage iframe {
    margin-top: -50px;
  } */
  .video-footage span {
    width: 100%;
    text-align: left;
  }
  .article .body {
    flex-direction: column;
  }
  .post-thumbnail {
    width: 100%;
    margin-bottom: 25px;
  }
}

/* FLEX */
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mb-50 {
  margin-bottom: 50px;
}